#cat{
    color: #010466;
    transition: all .7s;
}

#cat:hover{
    color: rgba(255, 0, 0, 0.788);
    letter-spacing: 5px;
}

.title:hover{
    color: rgba(255, 0, 0, 0.788);
    letter-spacing: 2px;
}

.product-grid9,.product-grid9 .product-image9{
    position:relative
}

.product-grid9{
    font-family:Poppins,sans-serif;
    z-index:1;
}

.product-grid9 .product-image9 a{
    display:block
}

.product-grid9 .product-image9 img{
    border-radius: 25px;
    width:100%;
    height:auto;
}

.product-grid9 .pic-1{
    opacity:1;
    transition:all .5s ease-out 0s;
}

.product-grid9:hover .pic-1{
    opacity:0;
}

.product-grid9 .pic-2{
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    transition:all .5s ease-out 0s;
}

.product-grid9:hover .pic-2{
    opacity:1;
}

.product-grid9 .product-full-view{
    color:#505050;
    background-color:#fff;
    font-size:16px;
    height:45px;
    width:45px;
    padding:18px;
    border-radius:100px 0 0;
    display:block;
    opacity:0;
    position:absolute;
    right:0;
    bottom:0;
    transition:all .3s ease 0s;
}

.product-grid9 .product-full-view:hover{
    color:#c0392b;
}

.product-grid9:hover .product-full-view{
    opacity:1;
}

.product-grid9 .product-content{
    padding:12px 12px 0;
    overflow:hidden;
    position:relative;
}

.product-content .rating{
    padding:0;
    margin:0 0 7px;
    list-style:none;
}

.product-grid9 .rating li{
    font-size:12px;
    color:#ffd200;
    transition:all .3s ease 0s;
}

.product-grid9 .rating li.disable{
    color:rgba(0,0,0,.2);
}

.product-grid9 .title{
    font-size:16px;
    font-weight:400;
    text-transform:capitalize;
    margin:0 0 3px;
    transition:all .3s ease 0s;
}

.product-grid9 .title a{
    color:rgba(0,0,0,.5);
}

.product-grid9 .title a:hover{
    color:#c0392b;
}

.product-grid9 .price{
    color:#000;
    font-size:17px;
    margin:0;
    display:block;
    transition:all .5s ease 0s;
}

.product-grid9:hover .price{
    opacity:0;
}

.product-grid9 .add-to-cart{
    display:block;
    color:#c0392b;
    font-weight:600;
    font-size:14px;
    opacity:0;
    position:absolute;
    left:10px;
    bottom:-20px;
    transition:all .5s ease 0s;
}

.product-grid9:hover .add-to-cart{
    opacity:1;
    bottom:0;
}

@media only screen and (max-width:990px){
    .product-grid9{
        margin-bottom:30px;
    }
}
