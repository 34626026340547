.unique-color-dark{
    background: rgb(12,0,150);
    background: linear-gradient(90deg, rgba(12,0,150,1) 0%, rgba(55,39,209,1) 41%, rgba(0,107,255,1) 63% );
}

#logo{
    height: 45px;
    width: 45px;
}

a{
    text-decoration: none;
    color: white;
}

a:hover{
    text-decoration: none;
    color: gold;
}

li:hover{
    color: gold;
}